import CircularProgress from '@mui/material/CircularProgress';
import { Grid } from '@mui/material';

function LoadingPage() {
  return (
    <Grid
      container
      direction="column"
      style={{ height: '100vh' }}
      alignItems="center"
      justifyContent="center"
    >
      <span
        className="screenReaderText"
        role="alert"
        aria-busy="true"
        id="loading-page-title"
      >
        Loading web page content
      </span>
      <CircularProgress size={150} />
    </Grid>
  );
}

export default LoadingPage;
